import React, { useState, useEffect, useRef, useCallback } from 'react'
import shuffle from 'lodash/shuffle'
import styles from './about.module.scss'

const imageDirectory = '/about-images/'
const imageFileNames = [
  '0A130007.jpg',
  '000B50012.jpg',
  '1746A966-DB33-469F-A390-0205B67CD5DB.JPG',
  '2042FB48-3063-41E4-9E00-1753882DD7FB.JPG',
  '00150015.jpg',
  '000417460002.jpg',
  '000417560006.jpg',
  '000417610005.jpg',
  '100014210009.jpg',
  'B5069FC3-A550-47E3-BE71-ACD842C37866.JPG',
  'IMG_1609.jpg',
  'IMG_3463.jpeg',
  'steve27-025.jpg'
]

const DISTANCE_THRESHOLD = 200 // How far to travel with the mouse before switching to another background image

const RootIndex = () => {
  const interactionTimer = useRef()
  const mouseX = useRef(null)
  const mouseY = useRef(null)
  const distanceTraveled = useRef(0)
  const isTouch = useRef(false)

  const [activeIndex, setActiveIndex] = useState(0);
  const [images, setImages] = useState(imageFileNames.map(fileName => `${imageDirectory}${fileName}`))
  const [backgroundVisible, setBackgroundVisible] = useState(false)

  const displayBackground = useCallback(() => {
    clearTimeout(interactionTimer.current);
    setBackgroundVisible(true);
  }, [setBackgroundVisible])

  const displayBackgroundWithTimeout = useCallback((ms = 500) => {
    displayBackground()
    hideBackgroundWithDelay(ms)
  }, [displayBackground, hideBackgroundWithDelay])

  const hideBackgroundWithDelay = useCallback((ms = 500) => {
    interactionTimer.current = setTimeout(() => {
      setBackgroundVisible(false)
    }, ms)
  }, [setBackgroundVisible])

  const goToNextImage = useCallback(() => {
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }, [activeIndex, setActiveIndex])

  const handleTouchstart = useCallback(e => {
    isTouch.current = true
    goToNextImage()
    displayBackground()
  }, [goToNextImage, displayBackground])

  const handleTouchend = useCallback(e => {
    hideBackgroundWithDelay(1000)
  }, [hideBackgroundWithDelay])

  const handleMousemove = useCallback(e => {
    if (isTouch.current) {
      return
    }

    displayBackgroundWithTimeout()
  
    if (mouseX.current >= 0 && mouseY.current >= 0) {
      distanceTraveled.current += Math.sqrt(Math.pow(mouseY.current - e.clientY, 2) + Math.pow(mouseX.current - e.clientX, 2));
    }

    if (distanceTraveled.current > DISTANCE_THRESHOLD) {
      goToNextImage()
      distanceTraveled.current = 0
    }      

    mouseX.current = e.clientX
    mouseY.current = e.clientY
  }, [goToNextImage])

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    }
  }, [])

  useEffect(() => {
    // Shuffle the image array
    setImages(prev => [
      ...shuffle(prev)
    ])

    // Set random Index
    setActiveIndex(Math.floor(Math.random()*imageFileNames.length))
  }, [setImages, setActiveIndex])


  useEffect(() => {
    window.addEventListener('mousemove', handleMousemove)
    window.addEventListener('touchstart', handleTouchstart)
    window.addEventListener('touchend', handleTouchend)

    return () => {
      window.removeEventListener('mousemove', handleMousemove)
      window.removeEventListener('touchstart', handleTouchstart)
      window.removeEventListener('touchend', handleTouchend)      
    }
  }, [handleMousemove, handleTouchstart, handleTouchend])


  const backgroundClasses = [
    styles.aboutBackground
  ]

  if (backgroundVisible) {
    backgroundClasses.push(styles.aboutBackgroundVisible)
  }

  return (
    <React.Fragment>     
      <div>
        <div className={styles.wrapper}>
          <div className={styles.contact}>
            <a href="mailto:steventraylor96@gmail.com" target="_blank">Contact</a>
            <br />
            <a href="mailto:hl@cameraclub.com" target="_blank">Agency</a>
          </div>          
          <div className={styles.aboutText}>
            <p>From Los Angeles.&nbsp;</p>
            <p>Creative.  Photo.  Film.</p>
          </div>
        </div>
      </div>

      <div 
        className={backgroundClasses.join(' ')}
      >
        {images.map((filePath, index) => {
          const classNames = [styles.aboutBackgroundImageFrame]

          if (index === activeIndex) {
            classNames.push(styles.aboutBackgroundImageFrameVisible)
          }

          return (
            <div
              key={`bg-${index}`}
              className={classNames.join(' ')}
            >
              <div className={styles.aboutBackgroundImage}>
                <img src={filePath} />
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default RootIndex